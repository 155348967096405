import React from "react";
import {Secondary} from "../../components/layouts/Secondary/Secondary";
import styled from "styled-components";

const Wrapper = styled.section`
  padding: 100px 11.32%;
  @media (max-width: 599px) {
    padding: 50px 8.32%;
  }
  main {
    li {
      list-style: lower-alpha;
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 34px;
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 27px;
      }
    }
    ol.list-style-lower-roman li {
      list-style: lower-roman;
    }
    h2 {
      margin-top: 30px;
      font-size: 1.6rem;
      @media (max-width: 599px) {
        font-size: 1.3rem;
        line-height: 1.6;
      }
    }
    p {
      margin-top: 10px;
      text-align: left;
    }
  }
`;

const PrivacyPolicy = () => (
  <Secondary>
    <Wrapper>
      <main>
        <h2 className="last-updated">Last updated: June 28, 2022</h2>
        <p>
          Your privacy and personal information are very important to us. The
          personal information Flahmingo Investments Inc. (&ldquo;
          <strong>Flahmingo</strong>&rdquo;, &ldquo;<strong>Us</strong>&rdquo;,
          &ldquo;<strong>us</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo;,
          &ldquo;<strong>Our</strong>&rdquo;, &ldquo;<strong>We</strong>&rdquo;
          and &ldquo;<strong>we</strong>&rdquo;) collect about you (&ldquo;
          <strong>You</strong>&rdquo;, &ldquo;<strong>you</strong>&rdquo;,
          &ldquo;
          <strong>Your</strong>&rdquo; or &ldquo;<strong>your</strong>&rdquo;)
          is managed in accordance with this Privacy Policy (&ldquo;
          <strong>Policy</strong>&rdquo; or &ldquo;
          <strong>Privacy Policy</strong>
          &rdquo;).&nbsp;&nbsp;This Privacy Policy applies to the collection,
          use and disclosure of your personal information, including personal
          information relating to your use of the{" "}
          <a href="http://www.flahmingo.com">www.flahmingo.com</a> website, and
          any and all services offered by Flahmingo (the &ldquo;
          <strong>Services</strong>&rdquo;).&nbsp;&nbsp;The Services may be
          provided by or through our Flahmingo mobile computer program(s) and
          software application (the &ldquo;<strong>Application</strong>&rdquo;),
          and/or through any and all other computer program(s), component(s) and
          module(s) offered by us and used by you and by or through the{" "}
          <a href="http://www.flahmingo.com">www.flahmingo.com</a> website or
          affiliated sites providing Flahmingo services (the{" "}
          <a href="http://www.flahmingo.com">www.flahmingo.com</a> website,
          together with all of the foregoing uses and services, to be
          hereinafter referred to as the &ldquo;<strong>Site</strong>&rdquo;).
        </p>
        <p>
          BY USING THE SITE, THE APPLICATION, OR THE SERVICES (INCLUDING
          REGISTERING FOR A USER ACCOUNT OR A TRADING ACCOUNT, AS APPLICABLE),
          YOU AGREE AND CONSENT TO THE RELEVANT PRACTICES AND POLICIES DESCRIBED
          IN THIS POLICY.&nbsp;&nbsp;IF YOU DO NOT AGREE TO THIS POLICY, DO NOT
          USE THE SITE, THE APPLICATION, OR THE SERVICES.
        </p>
        <h2>
          <strong>1. PRIVACY COMMITMENT&nbsp;</strong>
        </h2>
        <p>
          Flahmingo is committed to protecting our clients&rsquo; privacy and
          the confidentiality of their personal information in our possession.
          We collect, use and disclose personal information to operate our
          business and as required by law. Flahmingo collects personal
          information from our clients and prospective clients to provide
          Services to you and to fulfill our legal obligations. Understanding a
          client&rsquo;s financial position and personal circumstances enables
          us to ensure that investment recommendations are suitable and improve
          our Services. &nbsp;
        </p>
        <h2>
          <strong>2. CHANGES TO PRIVACY POLICY</strong>
        </h2>
        <p>
          This Policy describes our current privacy practices. We update this
          Policy on an ongoing basis to ensure consumers, applicants, customers
          and service providers, are aware of updates to our privacy practices,
          to streamline those practices and to comply with applicable laws.
          Please visit this Policy posted to the Site or the Application
          regularly for updates.&nbsp;
        </p>
        <p>
          Flahmingo may, at any time and from time to time at its sole
          discretion, update, revise, supplement, replace, and/or otherwise
          modify this Policy and/or impose new or additional rules, policies,
          terms, or conditions on You.&nbsp;&nbsp;Such updates, revisions,
          supplements, replacements, modifications, and additional rules,
          policies, terms, and conditions (collectively referred to as &ldquo;
          <strong>Revised Terms</strong>
          &rdquo;) will be effective immediately upon the Revised Terms being
          posted to the Site or the Application or by Flahmingo otherwise making
          them available to You (as the case may be). &nbsp;
        </p>
        <p>
          Any continued use of the Site, the Application, and/or
          Flahmingo&rsquo;s consumer investment/financial services platform and
          software, following such posting of the Revised Terms to the Site or
          the Application or by Flahmingo otherwise making them available to You
          (as the case may be), will be deemed to constitute acceptance of the
          Revised Terms.
        </p>
        <h2>
          <strong>3. ACCOUNTABILITY</strong>
        </h2>
        <p>
          Flahmingo is responsible for ensuring that we comply with this Policy
          and applicable privacy laws.
        </p>
        <p>
          We are responsible for personal information in our possession or
          custody, as well as personal information transferred to a third party
          for processing. More details on our third parties are provided below
          under &ldquo;Limiting Use, Disclosure and Retention&rdquo;.
        </p>
        <h2>
          <strong>
            4. PERSONAL INFORMATION WE COLLECT, AND LIMITING COLLECTION
          </strong>
        </h2>
        <p>
          Personal information is information about an identifiable individual,
          or any information that identifies you as an individual, as defined in
          the{" "}
          <em>Personal Information Protection and Electronic Documents Act </em>{" "}
          and in any applicable provincial legislation, including the{" "}
          <em>Alberta Personal Information Protection Act</em>{" "}
          (PIPA).&nbsp;&nbsp;It includes information that you provide to
          Flahmingo or that Flahmingo collects from any third party.
        </p>
        <p>
          Flahmingo collects information about identifiable individuals but does
          not collect any more personal information than is necessary to fulfill
          the purposes described in this Policy.&nbsp;&nbsp;Except where
          information is required to fulfill an identified purpose, you get to
          decide what information you want to share with
          us.&nbsp;&nbsp;Flahmingo only collects such Personal information as is
          reasonably necessary for Flahmingo to fulfill the purposes as set out
          in this Privacy Policy.&nbsp;&nbsp;The personal information that we
          collect includes information that you provide when creating an account
          with us.&nbsp;&nbsp;There are two kinds of accounts that may be
          registered with Flahmingo: a &ldquo;<strong>User Account</strong>
          &rdquo;, which allows you to use basic functionalities of the Site,
          the Application, and the Services including obtaining information on
          our Services and building your first test &ldquo;pie&rdquo;; and a
          &ldquo;
          <strong>Trading Account</strong>&rdquo;, which allows you to fund an
          account, purchase the securities for an active &ldquo;pie&rdquo; and
          execute trades through the Site, the Application, and the Services.
        </p>
        <p>
          <em>User Account Information.</em> We collect information that you
          provide, such as your name, phone number, address, city, and email
          address, in connection with your creation of a User
          Account.&nbsp;&nbsp;Such information is used only for the limited
          purpose of administering a User Account and the limited features and
          functionalities of the Site, the Application, and the Services
          available through a User Account.&nbsp;&nbsp;We may also collect and
          use basic contact information from you if you interact with us through
          social media or online ads, or participate in surveys, and we may use
          that information as described in this Policy. &nbsp;
        </p>
        <p>
          <em>Trading Account Information.</em> We collect information that you
          provide, such as your name, phone number, address, city, email
          address, birth date, copies or photographs of your physical
          identification document(s) such as your driver&rsquo;s license,
          passport, permanent residency card, or identity card, social insurance
          number (SIN), employment and compensation information, asset holdings
          and values, investment knowledge and objectives, and banking and
          credit information (including your chequing account information,
          transactional history, and account balances/limits), in connection
          with your creation of a Trading Account.&nbsp;&nbsp;Such information
          that you provide when creating a Trading Account may be used to help
          us choose the portfolio that best fits you and your needs, open and
          service your Trading Account, send and receive bill payments through
          your chequing account, detect and prevent fraud and other unauthorized
          or illegal activities, meet legal and regulatory requirements, tailor
          your Site, Application, and Service experiences, including making
          recommendations for Flahmingo products or services, transfer your
          account(s) to or from another institution, and as otherwise required
          or permitted by applicable law. Such information is also used for
          verifying your identity, ensuring that we comply with anti-money
          laundering (AML) obligations, and for the purpose of administering a
          Trading Account and the features and functionalities of the Site, the
          Application, and the Services available through a Trading Account, and
          other purposes as described in this Policy.&nbsp; &nbsp;
        </p>
        <p>
          <em>Digital and online information.</em> We may collect information
          from your mobile and online activity; for example, information about
          your web browser, time zone, Internet Protocol (IP) address, mobile
          device ID, application and website use, and history. Cookies (small
          computer files that a website&rsquo;s server places on your computer)
          collect information about your online behaviour. You can set your
          browser to reject cookies, but this may impair our ability to
          customize and test your experience, like remembering your preferred
          language or present location-specific information to
          you.&nbsp;&nbsp;By using and browsing the Site, you consent to cookies
          being used in accordance with this Privacy Policy. If you do not
          consent, you must disable cookies or refrain from using the
          Site.&nbsp;&nbsp;Cookies do not typically contain personal
          information, but personal information that we store about you may be
          linked to the information stored in and obtained from cookies.&nbsp;
        </p>
        <p>
          <em>Usage information.</em> If you sign up for one of our products or
          services, depending on the product or service, we may collect
          information regarding the use of our Site and Application, including
          screens visited and in-application behavior in order to monitor and
          improve the application.
        </p>
        <p>
          <em>Third party service providers.</em> We may collect information
          about you from third parties. We may collect publicly available
          information about you from online sources, or from surveys that you
          participate in. You may also enter into various services, agreements
          and reward and loyalty programs operated by third parties. The privacy
          policies of such third parties will apply to such transactions with
          third parties, and not this Policy.
        </p>
        <h2>
          <strong>5. IDENTIFYING PURPOSES</strong>
        </h2>
        <p>
          We will clearly identify the purposes for which personal information
          is collected, used or disclosed prior to, or at the time of,
          collection, and as in our customer agreements and disclosures.
        </p>
        <p>
          <em>Contacting you</em>. We may use your personal information to
          contact you. Your personal information allows us to contact you by
          various methods, including mail, email, SMS message, app push
          notification, and telephone at the contact points you&rsquo;ve
          provided to us. We will contact you to respond to your questions,
          proactively provide you with information about your accounts with us,
          inform you about our products and services, and update you on changes
          to our Site, Application, and other digital services we provide. We
          may use your personal information to send you important updates about
          your User Account and/or Trading Account. When you communicate online
          or by email, you should be aware that sending information over the
          internet is inherently insecure, as it can be intercepted or
          manipulated and retransmitted. &nbsp;
        </p>
        <p>
          <em>Authentication and compliance with money laundering rules</em>
          .&nbsp;&nbsp;We may use your personal information to authenticate you.
          We may also share application, transaction, authentication and
          &ldquo;know your client&rdquo; information with our partners,
          potential third-party providers, and other regulatory agencies as
          required by law.&nbsp;&nbsp;With your personal information, we can
          confirm your identity by sharing your personal information with third
          party identity verification providers to authenticate you when you
          apply for a Trading Account or other product or Service, to verify
          that it is you we are speaking with when you contact us or our
          Customer Service representatives to discuss your account, or identify
          you when you visit us online or on our mobile Application. &nbsp;
        </p>
        <p>
          <em>Providing services.&nbsp;&nbsp;</em>We may use your personal
          information to provide services to you. Your personal information may
          be used to maintain, service, process, analyze, audit and collect on
          your accounts. We may use your personal information to service your
          Trading Account, optimize our Site and Application, perform analytics,
          and provide you with a unique user experience.&nbsp;
        </p>
        <p>
          <em>Referral program.&nbsp;&nbsp;</em>If you participate in our
          referral program or other &ldquo;Invite a Friend&rdquo; features that
          may be available on our Services (the &ldquo;
          <strong>Referral Program</strong>
          &rdquo;), we collect the email address of the individual(s) who you
          wish to refer to Flahmingo. We use this information to send the
          recipient email correspondence containing information about the
          Referral Program. We do not use the email addresses submitted in these
          circumstances for any other purpose without the consent of the email
          recipient to do so. Please ensure that you only submit email addresses
          of individuals with whom you have a personal or family relationship
          and who would want to receive the email from you.
        </p>
        <p>
          <em>Improve and customize our products and services</em>. We may use
          your personal information to make improvements to our Site, our
          Application, and our Services. We may use your personal information to
          evaluate existing products and services, develop new products and
          services, drive strategy and improve the customer experience we offer.
          Information we collect is also valuable for our quality and servicing
          training programs.&nbsp;&nbsp;We also customize our Site and
          Application based on personal information collected, and use that
          information to understand and deliver products and services that you,
          and others who are similar to you, may find useful.
        </p>
        <p>
          <em>Marketing</em>.&nbsp;&nbsp;We may use your personal information to
          provide you with offers, advertising and marketing. Elements of your
          personal information may be used to determine your suitability, or to
          directly communicate with you, for targeted advertising, marketing,
          promotions, rewards programs, research or contests. You may see
          advertisements for our products and services on our Site, Application,
          or on third-party websites, based on your online or mobile activity
          and information you&rsquo;ve shared with third parties.&nbsp;&nbsp;You
          can opt-out of receiving marketing and promotional email from us by
          following the unsubscribe instructions contained in each of our email
          communications or by following the instructions in section 6 (Consent)
          below. Please note that you will continue to receive email regarding
          your User Account and/or Trading Account and transactions with us.
        </p>
        <p>
          <em>Employment</em>.&nbsp;&nbsp;If you inquire about or apply for a
          job with Flahmingo, you may provide us with certain personal
          information about yourself (such as that contained in a resume, cover
          letter, or similar employment-related materials). We use this
          information for the purpose of processing and responding to your
          application for current and future career opportunities.
        </p>
        <h2>
          <strong>6. CONSENT</strong>
        </h2>
        <p>
          If you apply for a product or service, communicate with us, or provide
          personal information to us in any way, you acknowledge your consent to
          the collection, use and disclosure of such personal information as you
          provide to us or that we otherwise collect as set out in this Policy
          and applicable laws. If we want to use your personal information for a
          purpose that was not disclosed at the time of initial consent, consent
          will be sought at the time of the new purpose.
        </p>
        <p>
          <em>Updating consent.</em> Subject to legal and contractual
          restrictions, you can, with reasonable notice to us, withdraw your
          consent to the collection, use, and disclosure of your personal
          information, other than the collection, use, and disclosure which is
          required for us to maintain your User Account or Trading Account and
          to provide Services that you sign up for and for which you were
          approved.
        </p>
        <p>
          <em>Opt out of mail, email and phone marketing.</em> You can request
          that we not contact you for advertising, marketing, promotions,
          rewards programs, research or contests, by updating your privacy
          preferences associated with your User Account or Trading Account or by
          otherwise updating your privacy preferences as set out herein. You
          should know that withdrawing your consent in these instances may
          result in you missing out on offers specific to your User Account or
          Trading Account.&nbsp;&nbsp;To opt out of our email marketing list
          after you have opted in, you may use the unsubscribe link provided in
          any email marketing material you receive.&nbsp;&nbsp;To update your
          privacy preferences, you may do so by updating your privacy
          preferences associated with your User Account or Trading Account, or
          by emailing{" "}
          <a href="mailto:compliance@flahmingo.com">compliance@flahmingo.com</a>{" "}
          and with subject line *Email-Opt Out.&nbsp;
        </p>
        <p>
          <em>Cookies.&nbsp;&nbsp;</em>If you do not wish to receive cookies, or
          want to be notified of when they are placed, you may set your web
          browser to do so, if your browser so permits. If you wish to delete
          cookies, you may set your web browser to do so, if your browser so
          permits. You acknowledge and agree that if cookies are turned off or
          deleted, you may not be able to view or access certain parts of the
          Site or use certain of certain Services available through the Site or
          certain features or functionalities thereof.
        </p>
        <h2>
          <strong>7. LIMITING USE, DISCLOSURE AND RETENTION</strong>
        </h2>
        <p>
          We limit use, disclosure, and retention of personal information to the
          purposes we identify, and as required by applicable laws.
        </p>
        <p>
          <em>Third parties.</em> We do not sell or rent customer lists or
          personal information to others. We may share your personal information
          with service providers who perform services for your Trading Account
          on our behalf. When you engage with other companies directly, or
          contact us through their platforms, their use of the personal
          information they collect from you is subject to the terms of their
          privacy policies. We may share your personal information with
          regulators, courts or other institutions as required by law.
        </p>
        <p>In particular:</p>
        <ol>
          <li>
            Third party verification providers &ndash; We may share your
            personal information with third party verification service
            providers, for the purposes of identity validation, risk assessment,
            anti-money laundering compliance, and maintaining accurate contact
            details for your Trading Account. Information shared for these
            purposes may include the following: name, address, phone number,
            birth date, copies or photographs of your physical identification
            document(s) such as your driver&rsquo;s license, passport, permanent
            residency card, or identity card, social insurance number (SIN), and
            &lsquo;selfie&rsquo; photos for identification validation purposes.
          </li>
          <li>
            Brokerage services &ndash; We may use third party brokerage service
            providers to broker trades and complete transactions for us, on
            behalf of you and our other users and customers.&nbsp;&nbsp;Such
            third party brokerage service providers are generally not provided
            with personal information, and information provided to brokerage
            service providers is generally de-identified and anonymized, and
            users and trades are identified by alphanumeric
            identifier.&nbsp;&nbsp;However, we may be asked by our third party
            brokerage service providers to provide them with personal
            information, including information provided by you in association
            with your creation of a Trading Account, to comply with their
            internal and external auditing and reporting policies, for insurance
            service providers, and for their own anti-money laundering
            compliance.&nbsp;
          </li>
          <li>
            Fraud detection services &ndash; We may use third parties to monitor
            your transactions to identify fraudulent activity on your Trading
            Account.
          </li>
          <li>
            Securities regulators &ndash; We may share your name, contact
            information and holdings with issuers of securities that you hold in
            your account and other persons/companies as required in accordance
            with securities law. Such issuers may require your personal
            information in order to deliver financial reports, tax documents and
            other relevant materials to you or to comply with applicable
            regulatory obligations. We may also disclose certain personal
            information, including the number of and purchase price of units
            purchased, to the applicable Canadian securities regulatory
            authority where required by law.
          </li>
          <li>
            Call centres and quality assurance providers &ndash; We may share
            access to your account information maintained on our financial
            systems, with call centres to service your Trading Account and
            provide you with customer support. We may share your customer
            experience with a third party to evaluate how we are doing, and
            ensure we are complying with applicable laws and regulations.
          </li>
          <li>
            Collections and law firms &ndash; We may share your information to
            help us collect a debt owed to us by you. Information shared may
            include the following: contact details, communication history,
            account details, and financial information.
          </li>
          <li>
            Legal process &ndash; Flahmingo and its third party service
            providers may provide your personal information in response to a
            search warrant or other legally valid inquiry or order, or to
            another organization for the purposes of investigating a breach of
            an agreement or contravention of law or detecting, suppressing or
            preventing fraud, or as otherwise required or permitted by
            applicable Canadian, US or other law (which may include lawful
            access requests by Canadian, US or other foreign courts, law
            enforcement or governmental authorities). We may also disclose
            personal information where necessary for the establishment, exercise
            or defence of legal claims and to investigate or prevent actual or
            suspect loss or harm to persons or property.
          </li>
        </ol>
        <p>
          Our service providers are given the information they need to perform
          their designated functions, and we do not authorize them to use or
          disclose personal information for their own marketing or other
          purposes.&nbsp;&nbsp;We also ensure that our third party service
          providers are bound by reasonable confidentiality and privacy
          obligations with respect to the personal information that we provide
          to them.
        </p>
        <p>
          <em>Assignees.</em> We may, at any time, sell, transfer or assign any
          or all of our rights to our Canadian business, including our
          interests, rights or obligations regarding your User Account or
          Trading Account with us. If we do so, we may share your personal
          information with prospective purchasers, transferees or
          assignees.&nbsp;&nbsp;In such transactions, customer information shall
          be treated as a transferred business asset. In the event that
          Flahmingo (or all, or substantially all, of its assets) were to be
          acquired by a third party, Flahmingo&rsquo;s customer information will
          be considered a transferred business asset.
        </p>
        <p>
          <em>Business Transactions</em>.&nbsp;&nbsp;We may transfer any
          information we have about you as an asset in connection with a
          prospective or completed merger or sale (including transfers made as
          part of insolvency or bankruptcy proceedings) involving all or part of
          Flahmingo or as part of a corporate reorganization or stock sale or
          other change in corporate control, including for the purpose of
          determining whether to proceed or continue with such transaction or
          business relationship.
        </p>
        <p>
          <em>Information processed outside Canada.</em> Your personal
          information may be stored and processed with approved third parties
          outside Canada, including the United States. If a third party
          processes or stores information outside Canada, foreign governments,
          courts or regulatory agencies may therefore be able to obtain such
          personal information through the laws of the foreign
          jurisdiction.&nbsp;&nbsp;In the event personal information is
          transferred to the US or other foreign jurisdiction, it will be
          subject to the laws of that jurisdiction and may be disclosed to or
          accessed by the courts, law enforcement and governmental authorities
          in accordance with the laws of those jurisdictions.
        </p>
        <h2>
          <strong>8. ACCURACY</strong>
        </h2>
        <p>
          We take steps to ensure that your personal information is as accurate,
          complete and up to date as is necessary for the purposes for which it
          is to be used.&nbsp;&nbsp;If you move, change your phone number, or
          change your email address, or your personal information is otherwise
          out of date, you are required to update your information with us.
        </p>
        <p>
          If you believe that the personal information we have about you is not
          accurate or is incomplete, please contact us by updating your
          information through our web or mobile apps, or by notifying us by
          email at{" "}
          <a href="mailto:compliance@flahmingo.com">compliance@flahmingo.com</a>
          .&nbsp;
        </p>
        <p>
          Depending on your location and subject to applicable law, you may have
          additional rights with respect to your personally identifiable
          information, including:&nbsp;
        </p>
        <ol className="list-style-lower-roman">
          <li>
            &ldquo;Right to be forgotten&rdquo; - the right to request that we
            erase your personally identifiable information in certain
            circumstances provided by applicable law;&nbsp;
          </li>
          <li>
            &ldquo;Data portability&rdquo; - the right to request that we send
            to another company, where technically feasible, your personally
            identifiable information; and
          </li>
          <li>
            the right to request that we restrict the processing of your
            personally identifiable information in certain circumstances; for
            example, if you contest the accuracy of the personally identifiable
            information while we are verifying the accuracy thereof.
          </li>
        </ol>
        <p>
          If you wish to exercise any of those rights, please contact us by
          email at{" "}
          <a href="mailto:compliance@flahmingo.com">compliance@flahmingo.com</a>
          .&nbsp;
        </p>
        <h2>
          <strong>9. SAFEGUARDS</strong>
        </h2>
        <p>
          We use a variety of procedures and practices appropriate to the
          sensitivity of personal information to protect against loss, theft and
          unauthorized access. Access to your information is restricted to those
          individuals and parties who require access for the purposes set out in
          this Policy. &nbsp;
        </p>
        <p>
          You acknowledge and agree that we may, at our sole discretion, use a
          variety of security technologies and procedures to help protect your
          personally identifiable information from unauthorized access, use, and
          disclosure.&nbsp;&nbsp;Flahmingo secures the Personal Information you
          provide on computer servers in a controlled, secure environment,
          protected from unauthorized access, use or
          disclosure.&nbsp;&nbsp;Additionally, we have electronic protection
          (such as passwords and encryption) and safe business practices. We
          also train our staff on how to safeguard personal information.
        </p>
        <p>
          While we take all reasonable measures to protect data, there is always
          inherent risk when providing personal information.&nbsp;&nbsp;There
          are always risks associated with providing personal information and
          sensitive data, whether in person, by phone, mobile, or tablet device,
          via the Internet or via other technologies. The transmission of
          information and data over the Internet is inherently insecure, and
          there are no security systems that are completely safe or fool-proof
          against hacking or tampering. Flahmingo endeavours to take
          commercially reasonable precautions to prevent and minimize such risks
          in connection with your use of the Site, the Application, and our
          Services, but Flahmingo offers no (and hereby expressly disclaims any)
          guarantee, representation, warranty, or covenant of any kind with
          respect to the same.
        </p>
        <p>
          You can help us safeguard your personal information. If you contact us
          through email or social media, you should avoid sending highly
          sensitive information, such as your banking information or any
          specific account information. If someone purporting to represent us
          contacts you and requests your personal or financial information, and
          you are suspicious, you can always contact us to confirm that the
          request was legitimate. We also recommend that you use unique and
          strong passwords for your online accounts and that you not share your
          passwords with anyone.
        </p>
        <h2>
          <strong>10. THIRD PARTY WEBSITES AND SERVICES</strong>
        </h2>
        <p>
          Except as provided herein, we will not provide any of your personal
          information to these third parties without your
          consent.&nbsp;&nbsp;Our Services, Site, and Application may contain
          links to other websites that we do not own or operate. These links are
          not intended as an endorsement of or referral to the linked websites.
          The linked websites have separate and independent privacy policies,
          notices and terms of use, which we recommend you read carefully. We do
          not have any control over such websites, and therefore we have no
          responsibility or liability for the manner in which the organizations
          that operate such linked websites may collect, use or disclose, secure
          and otherwise treat your personal information.
        </p>
        <h2>
          <strong>
            11. OPENNESS, INDIVIDUAL ACCESS AND CHALLENGING COMPLIANCE
          </strong>
        </h2>
        <p>
          You can contact Flahmingo to request access to the personal
          information we have on file for you. We will provide you with the
          personal information we have, subject to certain considerations
          specified by law.&nbsp;
        </p>
        <p>
          Flahmingo welcomes your comments regarding this Privacy Policy. If you
          would like more information on this Privacy Policy or
          Flahmingo&rsquo;s organizational privacy policies, or if believe that
          Flahmingo has not adhered to the terms of this Privacy Policy, please
          contact Flahmingo&rsquo;s designated Privacy Officer at{" "}
          <a href="mailto:compliance@flahmingo.com">compliance@flahmingo.com</a>
          . We will use commercially reasonable efforts to promptly identify and
          remedy any failure by Flahmingo to adhere to the terms of this Privacy
          Policy.
        </p>
      </main>
    </Wrapper>
  </Secondary>
);
export default PrivacyPolicy;